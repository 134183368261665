<template>
  <div class="readme-edits">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="12" sm="12" xs="12">
          <v-card class="" elevation="2" outlined tile>
            <v-card-title>
              Правила редактирования
            </v-card-title>
            <v-card-text>
              <p>1. Главное — правильный перевод. Оформление вторично.</p>
              <p>2. В одной статье могут быть объединены несколько слов с разными написаниями/чтениями при условии, что они полностью синонимичны и взаимозаменяемы (полное совпадение всех значений, переводов, производных и словосочетаний).</p>
              <p>3. Квадратные скобки используются для взаимозаменяемых слов, круглыми обозначается факультативная часть определения.</p>
              <p>4. Синонимы отделяются запятой, более отдалённые значения — точкой с запятой, разные значения пишутся в разных полях.</p>
              <p>5. Пояснения заключаются в скобки и выделяются курсивом (тег [i][/i]).</p>
              <p>6. Общепринятые сокращения выделяются тегом [p][/p].</p>
              <p>7. Производные слова (образованные добавлением префикса/суффикса) выносятся на новую строку, при этом производная часть выделяется через {~ } и записывается латиницей.</p>
              <!-- <p></p> -->
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss">
.markdown {
    white-space: pre-line;
    word-wrap: break-word;
}
</style>
